<template>
  <div>
    <swiper
      v-if="items.length"
      v-bind="swiperConfig"
      :class="{'!overflow-visible': overflow}"
      @slide-change="onSlideChange"
    >
      <swiper-slide
        v-for="(item, index) in items"
        :key="index"
      >
        <slot
          name="item"
          v-bind="{...item, index: index}"
        />
      </swiper-slide>
      <template #container-end>
        <BaseCarouselBar
          v-if="bar"
          :size="items.length"
        />
        <div
          v-else
          class="container flex items-center justify-between pt-4"
        >
          <BaseCarouselPrev />
          <BaseCarouselDots :size="items.length" />
          <BaseCarouselNext />
        </div>
      </template>
    </swiper>
  </div>
</template>

<script setup lang="ts">
import 'swiper/css';
import {Swiper, SwiperSlide} from 'swiper/vue';

const props = defineProps<{
  items: [],
  bar?: boolean,
  config?: object,
}>();

const overflow = ref(false);

const onSlideChange = (swiper) => {
  if (!overflow.value && swiper.realIndex !== 0) {
    overflow.value = true;
  }
};

const defaultConfig = {
  loop: true,
  threshold: 10,
  slidesPerView: 1.3,
  spaceBetween: 24,
  centeredSlides: true,
  breakpoints: {
    786: {slidesPerView: 2.5},
  },
};

const swiperConfig = {
  ...defaultConfig,
  ...props.config,
};
</script>
